export enum ACTIONS_CONSTANTS {
  // dashboard page
  GET_SALES_SUMMARY = 'GET_SALES_SUMMARY',
  // uses prefix `DASHBOARD` `GET_ORDERS_SUMMARY` is already exist
  DASHBOARD_GET_ORDERS_SUMMARY = 'DASHBOARD_GET_ORDERS_SUMMARY',
  SET_SALES_SUMMARY = 'SET_SALES_SUMMARY',
  SHOW_ADDRESS_VALIDATION_FAILED_TOAST = 'SHOW_ADDRESS_VALIDATION_FAILED_TOAST',
  HIDE_ADDRESS_VALIDATION_FAILED_TOAST = 'HIDE_ADDRESS_VALIDATION_FAILED_TOAST',
  HIDE_ADDRESS_VALIDATION_SUCCESS_TOAST = 'HIDE_ADDRESS_VALIDATION_SUCCESS_TOAST',
  DASHBOARD_SET_ORDERS_SUMMARY = 'DASHBOARD_SET_ORDERS_SUMMARY',
  SET_DASHBOARD_FETCHING = 'SET_DASHBOARD_FETCHING',
  // orders page
  ORDERS_GET_ORDERS_SUMMARY = 'GET_ORDERS_SUMMARY',
  GET_UNPAID_ORDERS_SUMMARY = 'GET_UNPAID_ORDERS_SUMMARY',
  GET_ORDERS_LIST = 'GET_ORDERS_LIST',
  GET_UNPAID_ORDERS_LIST = 'GET_UNPAID_ORDERS_LIST',
  GET_UNPAID_ORDERS_TOTALS_ALL = 'GET_UNPAID_ORDERS_TOTALS_ALL',
  GET_RETAILERS_FOR_UNPAID_ORDERS = 'GET_RETAILERS_FOR_UNPAID_ORDERS',
  SET_RETAILERS_FOR_UNPAID_ORDERS = 'SET_RETAILERS_FOR_UNPAID_ORDERS',
  SET_ORDERS_LIST_FETCHING = 'SET_ORDERS_LIST_FETCHING',
  SET_UNPAID_ORDERS_LIST_FETCHING = 'SET_UNPAID_ORDERS_LIST_FETCHING',
  SET_ORDERS_SUMMARY_FETCHING = 'SET_ORDERS_SUMMARY_FETCHING',
  SET_PAYOUT_FETCHING = 'SET_PAYOUT_FETCHING',
  SET_UNPAID_ORDERS_TOTALS_DETAILS = 'SET_UNPAID_ORDERS_TOTALS_DETAILS',
  ORDERS_SET_ORDERS_SUMMARY = 'ORDERS_SET_ORDERS_SUMMARY',
  SET_UNPAID_ORDERS_SUMMARY = 'SET_UNPAID_ORDERS_SUMMARY',
  SET_ORDERS_LIST = 'SET_ORDERS_LIST',
  SET_UNPAID_ORDERS_LIST = 'SET_UNPAID_ORDERS_LIST',
  ORDER_LIST_FILTERS = 'ORDER_LIST_FILTERS',
  SET_ORDER_LIST_FILTERS = 'SET_ORDER_LIST_FILTERS',
  GET_PAYOUTS = 'GET_PAYOUTS',
  SET_PAYOUT_LIST = 'SET_PAYOUT_LIST',
  CREATE_NEW_PAYOUT = 'CREATE_NEW_PAYOUT',
  UPDATE_PAYOUT = 'UPDATE_PAYOUT',
  // products page
  GET_PRODUCTS = 'GET_PRODUCTS',
  SET_PRODUCTS_FETCHING = 'SET_PRODUCTS_FETCHING',
  SET_PRODUCTS = 'SET_PRODUCTS',
  GET_PRODUCT_VARIANTS = 'GET_PRODUCT_VARIANTS',
  SET_PRODUCT_VARIANTS = 'SET_PRODUCT_VARIANTS',
  GET_PRODUCT_FILTER_OPTIONS = 'GET_PRODUCT_FILTER_OPTIONS',
  SET_PRODUCT_FILTER_OPTIONS = 'SET_PRODUCT_FILTER_OPTIONS',
  SET_PRODUCTS_VARIANTS = 'SET_PRODUCTS_VARIANTS',
  CHANGE_AVAILABLE_GROUPS = 'CHANGE_AVAILABLE_GROUPS',
  ADD_PRODUCTS_TO_MARKETPLACE = 'ADD_PRODUCTS_TO_MARKETPLACE',
  SET_LAST_PRODUCTS_AVAILABILITY_UPDATE = 'SET_LAST_PRODUCTS_AVAILABILITY_UPDATE',
  REMOVE_LAST_PRODUCTS_AVAILABILITY_UPDATE = 'REMOVE_LAST_PRODUCTS_AVAILABILITY_UPDATE',
  UPDATE_VARIANTS_PRICE_FOR_RETAILERS = 'UPDATE_VARIANTS_PRICE_FOR_RETAILERS',
  UPDATE_VARIANTS_MSRP = 'UPDATE_VARIANTS_MSRP',
  UPDATE_VARIANT = 'UPDATE_VARIANT',
  SHOW_SUCCESS_VARIANT_PRICE_UPDATE_TOAST = 'SHOW_SUCCESS_VARIANT_PRICE_UPDATE_TOAST',
  HIDE_SUCCESS_VARIANT_PRICE_UPDATE_TOAST = 'HIDE_SUCCESS_VARIANT_PRICE_UPDATE_TOAST',
  SET_PRODUCT_FILTERS = 'SET_FILTERS',
  CHANGE_AVAILABLE_GROUPS_FOR_ALL_PRODUCTS = 'CHANGE_AVAILABLE_GROUPS_FOR_ALL_PRODUCTS',
  ADD_ALL_PRODUCTS_TO_MARKETPLACE = 'ADD_ALL_PRODUCTS_TO_MARKETPLACE',
  RESET_PRODUCT_FILTERS = 'RESET_PRODUCT_FILTERS',
  SET_PRODUCT_QUERY_FILTER = 'SET_PRODUCT_QUERY_FILTER',
  SET_PRODUCT_VENDOR_FILTER = 'SET_PRODUCT_VENDOR_FILTER',
  SET_SHOPIFY_STATUS_FILTER = 'SET_SHOPIFY_STATUS_FILTER',
  SET_PRODUCT_TYPE_FILTER = 'SET_PRODUCT_TYPE_FILTER',
  SET_PRODUCT_STOCK_FILTER = 'SET_PRODUCT_STOCK_FILTER',
  SET_PRODUCT_AVAILABILITY_FILTER = 'SET_PRODUCT_AVAILABILITY_FILTER',
  SET_PRODUCT_GROUPS_FILTER = 'SET_PRODUCT_GROUPS_FILTER',
  SET_PRODUCT_PAGE_FILTER = 'SET_PRODUCT_PAGE_FILTER',
  SET_PRODUCT_LIMIT_FILTER = 'SET_PRODUCT_LIMIT_FILTER',
  SET_PRODUCT_INVENTORY_FILTER = 'SET_PRODUCT_INVENTORY_FILTER',
  SET_UPDATING_ALL_PRODUCTS = 'SET_UPDATING_ALL_PRODUCTS',
  // settings
  GET_SETTINGS = 'GET_SETTINGS',
  SET_SETTINGS_FROM_API = 'SET_SETTINGS_FROM_API',
  SET_SETTINGS = 'SET_SETTINGS',
  // orders
  GET_ORDER_DETAILS = 'GET_ORDER_DETAILS',
  SET_ORDER_DETAILS = 'SET_ORDER_DETAILS',
  ACCEPT_CANCELLATION_REQUEST = 'ACCEPT_CANCELLATION_REQUEST',
  DECLINE_CANCELLATION_REQUEST = 'DECLINE_CANCELLATION_REQUEST',
  REFUND_ORDER = 'REFUND_ORDER',
  HIDE_ACCEPTING_CANCELLATION_TOAST = 'HIDE_ACCEPTING_CANCELLATION_TOAST',
  HIDE_DECLINING_CANCELLATION_TOAST = 'HIDE_DECLINING_CANCELLATION_TOAST',
  CANCEL_LINE_ITEMS = 'CANCEL_LINE_ITEMS',
  DECREMENT_PAYOUT_PENDING_PAYOUTS_COUNT = 'DECREMENT_PAYOUT_PENDING_PAYOUTS_COUNT',
  // status
  GET_STATUS = 'GET_STATUS',
  SET_STATUS = 'SET_STATUS',
  SET_UPDATING_PRICE_MAPPING = 'SET_UPDATING_PRICE_MAPPING',
  REQUEST_MARKETPLACE_APPROVAL = 'REQUEST_MARKETPLACE_APPROVAL',
}
