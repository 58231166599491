import {
  CANCELLATION_REASON,
  CancellationInitiator,
  ILineItemCancellation,
} from '../../core/interfaces/ILineItemCancellation';
import { IAddress } from '../../shopify-supplier/interfaces/IOrder';

export interface IRetailerOrder {
  id: string;
  orderName: string;
  date: string;
  total: number;
  status: RetailerOrderStatus;
  itemsCount: number;
  customerFirstName?: string;
  customerLastName?: string;
  customerCompany?: string;
  purchaseOrdersCount: number;
}
interface IOrderSalesTotals {
  subtotal: number;
  shipping: number;
  total: number;
  refunded?: number;
}

interface IOrderPurchaseTotals {
  subtotal: number;
  shipping: number;
  fee: number;
  handlingFee: number;
  total: number;
  refunded?: number;
}

export interface IOrderDetailsTotals {
  profit: number;
  margin: number;
  selectedShipping: string;
  feePercent: number;
  retailer: IOrderSalesTotals;
  supplier: IOrderPurchaseTotals;
  manualRefund: {
    purchaseOrderId: string;
    purchaseOrderName: string;
    paidViaCrowdship: boolean;
    amount: number;
    reason?: string;
  }[];
}
export interface IOrderDetailsLineItem {
  id: string;
  image?: string;
  title?: string;
  productGroupTitle: string;
  sku: string;

  supplierName: string;
  supplierSku: string;
  supplierProductGroupTitle: string;
  supplierTitle: string;

  trackingNumbers: string[];
  trackingUrls: string[];
  trackingCompanies: string[];
  fulfilledQty: number;
  totalQty: number;

  price: number;
  cost: number;
  paidViaCrowdship: boolean;

  cancellations: ILineItemCancellation[];

  supplierOrderName: string;
  purchaseOrderName: string;
  purchaseOrderId: string;
}

export type RetailerOrderStatus =
  | 'fulfilled'
  | 'partially fulfilled'
  | 'partially unfulfilled'
  | 'cancelled'
  | 'unfulfilled'
  | 'payment failed'
  | 'account suspended'
  | 'processing'
  | 'error';

export enum RETAILER_ORDER_PROCESSING_ERROR {
  NO_SHIPPING_ADDRESS = 'No shipping address',
  INVALID_ADDRESS = 'Invalid address',
  CANNOT_CALCULATE_SHIPPING = 'Cannot calculate shipping',
  PENDING_REPROCESSING = 'Pending reprocessing',
  UNKNOWN = 'Unknown',
}

export interface IRetailerOrderProcessingError {
  type: RETAILER_ORDER_PROCESSING_ERROR;
  message: string;
}

export interface ICustomerAddress {
  id?: number;
  address1: string;
  address2?: string;
  city: string;
  company: string | null;
  country: string;
  countryCode: string;
  countryName: string;
  customerId?: number;
  default: boolean;
  fname: string;
  lname: string;
  latitude: number | null;
  longitude: number | null;
  name: string;
  phone: string | null;
  province: string | null;
  provinceCode: string | null;
  zip: string;
}

export interface IPurchaseOrderDetailsForSalesOrder {
  id: string;
  status: string;
  paidViaCrowdship: boolean;
  name: string;
  supplierOrderName: string;
  supplierName: string;
}

export interface IOrderDetails {
  id: string;
  name: string;
  createdAt: string;
  status: RetailerOrderStatus;
  crowdshipItemsCount: number;
  shippingAddress?: IAddress;
  lineItems: IOrderDetailsLineItem[];
  purchaseOrders: IPurchaseOrderDetailsForSalesOrder[];
  cancellations: ICancellation[];
  totals: IOrderDetailsTotals;
  shopifyOrderId?: number;
  error?: IRetailerOrderProcessingError;
}

export interface ICancellation {
  _id: string;
  lineItems: string[];
  pending: boolean;

  initiatedBy: CancellationInitiator;
  reason: CANCELLATION_REASON;
  comment?: string;

  createdAt: string;
  updatedAt: string;
}

export interface IRetailerOrdersSummary {
  totalOrdersCount: number;
  unfulfilledOrdersCount: number;
  totalEarned: number;
}

export interface IRetailerPurchaseOrderForList {
  id: string;
  name: string;
  createdAt: string;
  supplierName: string;
  total: number;
  salesOrderName: string;
  invoiceId?: string;
  itemsCount: number;
  status: RetailerOrderStatus;
  paymentStatus: string;
  customerFirstName: string;
  customerLastName: string;
  customerCompany: string;
}

export interface IRetailerPurchaseOrderDetails {
  id: string;
  name: string;
  createdAt: string;
  salesTotals: IOrderSalesTotals;
  purchaseTotals: IOrderPurchaseTotals;
  amountPaid: number;
  amountDue: number;
  profit: number;
  margin: number;
  refunds: { reason?: string; amount: number }[];

  invoiceId?: string;
  invoiceDownloadLink?: string;
  transactionFeeInvoiceId?: string;
  feeInvoiceDownloadLink?: string;

  shippingAddress?: ICustomerAddress;
  selectedShipping: string;
  feePercent: number;

  status: RetailerOrderStatus;
  lineItems: IRetailerPurchaseOrderLineItems[];

  supplierName: string;
  supplierEmail: string;
  supplierPhone: string;
  supplierLogo?: string;
  supplierOrderName: string;

  salesOrderName: string;
  salesOrderId: string;
  salesOrderShopifyId?: number;

  shippingInvoices: IShippingInvoice[];

  error?: {
    type: RETAILER_ORDER_PROCESSING_ERROR;
    message: string;
  };
}

export interface IShippingInvoice {
  shippingInvoiceId: string;
  shippingPaidAt: string;
  refunded: boolean;
  labelId: string;
  labelCost: number;
  providerName: CAREER_SERVICE_PROVIDERS;
  downloadUrl?: string;
  creditNoteId?: string;
}

export enum CAREER_SERVICE_PROVIDERS {
  USPS = 'USPS',
  UPS = 'UPS',
  FEDEX = 'FedEx',
  DHL_EXPRESS = 'DHL Express',
  CANADA_POST = 'Canada Post',
}

interface IRetailerPurchaseOrderLineItems {
  id: string;

  image?: string;
  title?: string;
  productGroupTitle: string;
  sku: string;
  supplierSku: string;
  supplierProductGroupTitle: string;
  supplierTitle?: string;

  trackingNumbers: string[];
  trackingUrls: string[];
  trackingCompanies: string[];
  fulfilledQty: number;
  totalQty: number;
  cancellations: ILineItemCancellation[];
  price: number;
  cost: number;
}

export interface IRetailerPurchaseOrderWithUnpaidFeeForList {
  id: string;
  name: string;
  createdAt: string;
  total: number;
  fee: number;
  supplierName: string;
}
